import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Row, Col, Accordion } from 'react-bootstrap';
import './ResultPage.css';
import QuestionListModal from './QuestionListModal';

const ResultPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showQuestionListModal, setShowQuestionListModal] = useState(false);
  
  const { state } = location;
  const { 
    message, 
    ai_data, 
    actionType, 
    questionList, 
    class_id, 
    subject_id, 
    topic_ids, 
    subtopic,
    questionImage,
    questionNumber
  } = state || {};
  
  const { question, ai_explaination, student_answer, concepts, comment, concepts_used, solution } = ai_data || {};

  const handleBackToDashboard = () => {
    navigate('/student-dash');
  };

  const handleShowQuestionList = () => {
    setShowQuestionListModal(true);
  };

  const handleCloseQuestionList = () => {
    setShowQuestionListModal(false);
  };

  const handleQuestionSelect = (selectedQuestion, index, selectedImage) => {
    navigate('/solvequestion', { 
      state: { 
        question: selectedQuestion, 
        index, 
        questionList, 
        class_id,
        subject_id,
        topic_ids,
        subtopic,
        image: selectedImage
      } 
    });
  };

  const handlePracticeSimilar = () => {
    if (!question) {
      console.error('No question available');
      return;
    }

    navigate('/similar-questions', {
      state: {
        class_id,
        subject_id,
        topic_ids,
        subtopic,
        originalQuestion: question
      }
    });
  };

  const formatExampleContent = (example) => {
    if (!example) return null;

    // Separate the initial part (before first Step) and the steps
    const [intro, ...stepParts] = example.split(/Step \d+:/);
    
    return (
      <div className="example-content">
        <p>{intro.trim()}</p>
        <div className="example-steps">
          {stepParts.map((step, index) => (
            <div key={index} className="example-step">
              <strong>{`Step ${index + 1}:`}</strong>{step.trim()}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderContentBasedOnAction = () => {
    switch (actionType) {
      case 'submit':
        return (
          <div className="result-explanation">
            <p><strong>Student Answer:</strong> {student_answer}</p>
          </div>
        );
      case 'solve':
        return (
          <div className="result-explanation">
            <p className="solution-header">AI Solution:</p>
            <div className="solution-steps">
              {ai_explaination && ai_explaination.map((step, index) => (
                <div key={index} className="solution-step">
                  {step}
                </div>
              ))}
            </div>
          </div>
        );
      case 'correct':
        return (
          <>
            <div className="result-explanation">
              <p><strong>Student Answer:</strong> {student_answer}</p>
            </div>
            <div className="result-explanation">
              <p className="solution-header">AI Solution:</p>
              <div className="solution-steps">
                {ai_explaination && ai_explaination.map((step, index) => (
                  <div key={index} className="solution-step">
                    {step}
                  </div>
                ))}
              </div>
            </div>
            <div className="result-explanation">
              <p><strong>Comments:</strong> {comment}</p>
            </div>
            <div className="result-explanation">
              <p><strong>Concepts Used:</strong> {concepts_used}</p>
            </div>
          </>
        );
      case 'explain':
        return (
          <>
            {concepts && (
              <Accordion defaultActiveKey="0" className="result-accordion">
                {concepts.map((conceptItem, index) => (
                  <Accordion.Item eventKey={index.toString()} key={index} className="accordion-item">
                    <Accordion.Header>
                      <strong>{`Concept ${index + 1}`}</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="concept-title"><strong>{conceptItem.concept}</strong></p>
                      <p className="chapter-name"><strong>Chapter Name:</strong> {conceptItem.chapter}</p>
                      <div className="example-section">
                        <p className="example-header"><strong>Example:</strong></p>
                        {formatExampleContent(conceptItem['example'])}
                      </div>
                      <p className="explanation"><strong>Explanation:</strong> {conceptItem.explanation}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </>
        );
      default:
        return <p>No action type provided. Unable to display results.</p>;
    }
  };

  return (
    <Container className="result-page-container">
      <Row className="justify-content-center">
        <Col md={8} className="result-content">
          <h2 className="result-title">Result</h2>
          <div className="result-question">
            <p><strong>Question {questionNumber}:</strong> {question}</p>
            {questionImage && (
              <div className="question-image-container">
                <img 
                  src={questionImage}
                  alt="Question"
                  className="question-image"
                />
              </div>
            )}
            {solution && solution.length > 0 && (
              <div className="result-solution">
                <p className="solution-header">Solution:</p>
                <div className="solution-steps">
                  {solution.map((step, index) => (
                    <div key={index} className="solution-step">
                      {step}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {renderContentBasedOnAction()}
          <div className="result-buttons">
            <Button 
              variant="primary" 
              onClick={handleShowQuestionList}
              className="next-question-btn"
            >
              Next Question
            </Button>
            <Button 
              variant="outline-primary" 
              onClick={handleBackToDashboard}
              className="dashboard-btn"
            >
              Back to Dashboard
            </Button>
            <Button 
              variant="primary" 
              onClick={handlePracticeSimilar}
              className="practice-btn"
            >
              Practice Similar Questions
            </Button>
          </div>
        </Col>
      </Row>

      <QuestionListModal 
        show={showQuestionListModal} 
        onHide={handleCloseQuestionList} 
        questionList={questionList} 
        onQuestionClick={handleQuestionSelect} 
      />
    </Container>
  );
};

export default ResultPage;