import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axiosInstance from '../api/axiosInstance';
import './SolveQuestion.css';
import QuestionListModal from './QuestionListModal';

function SolveQuestion() {
  const location = useLocation();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [isSolveEnabled, setIsSolveEnabled] = useState(true);
  const [showQuestionListModal, setShowQuestionListModal] = useState(false);

  // Extract data from location state with default values
  const { 
    question = '', 
    questionNumber = 1,
    questionList = [], 
    class_id, 
    subject_id, 
    topic_ids, 
    subtopic,
    image = '' // Extract image from state
  } = location.state || {};

  // Initialize currentQuestion state
  const [currentQuestion, setCurrentQuestion] = useState({
    question: question,
    questionNumber: questionNumber,
    image: image // Use the image passed from state
  });

  // Update currentQuestion when location state changes
  useEffect(() => {
    if (location.state) {
      setCurrentQuestion({
        question: location.state.question || '',
        questionNumber: location.state.questionNumber || 1,
        image: location.state.image || '' // Update image when state changes
      });
    }
  }, [location.state]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
    setIsSolveEnabled(files.length === 0);
  };

  const sendFormData = async (flags = {}, actionType) => {
    const formData = new FormData();
    formData.append('class_id', class_id);
    formData.append('subject_id', subject_id);
    formData.append('topic_ids', topic_ids);
    formData.append('question', currentQuestion.question);
    formData.append('subtopic', subtopic);

    Object.entries(flags).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (flags.correct || flags.submit) {
      images.forEach((image) => {
        formData.append('ans_img', image);
      });
    }

    try {
      const response = await axiosInstance.post('/anssubmit/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      
      // Navigate with both question text and image
      navigate('/resultpage', { 
        state: {
          ...response.data, 
          actionType, 
          questionList,
          class_id, 
          subject_id, 
          topic_ids, 
          subtopic,
          questionImage: currentQuestion.image, // Pass the question image
          questionNumber: currentQuestion.questionNumber
        }
      });
    } catch (error) {
      console.error('API Error:', error);
      alert('Failed to perform the action. Please try again.');
    }
  };

  const handleSubmit = () => sendFormData({ submit: true }, 'submit');
  const handleSolve = () => sendFormData({ solve: true }, 'solve');
  const handleCorrect = () => sendFormData({ correct: true, submit: false }, 'correct');
  const handleExplain = () => sendFormData({ explain: true }, 'explain');

  const handleCancelImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    setIsSolveEnabled(updatedImages.length === 0);
  };

  const handleQuestionSelect = (selectedQuestion, selectedIndex, selectedImage) => {
    setCurrentQuestion({
      question: selectedQuestion,
      questionNumber: selectedIndex + 1,
      image: selectedImage
    });
    setShowQuestionListModal(false);
  };

  return (
    <div className="solve-question-wrapper">
      <div className="solve-question-container">
        {/* Question Display Section */}
        <div className="question-text-container">
          <span className="question-title">Question {currentQuestion.questionNumber}</span>
          {currentQuestion.image && (
            <img 
              src={currentQuestion.image}
              alt="Question"
              className="question-image"
            />
          )}
          <p className="question-text">{currentQuestion.question}</p>
        </div>

        {/* Image Upload Section */}
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Group controlId="formImage">
            <Form.Label>Upload Images</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </Form.Group>
        </Form>

        {/* Image Previews */}
        <div className="uploaded-images">
          {images.map((image, index) => (
            <div key={index} className="image-preview-container">
              <img
                src={URL.createObjectURL(image)}
                alt={`Preview ${index}`}
                className="image-preview"
              />
              <Button
                variant="danger"
                className="btn-cancel"
                onClick={() => handleCancelImage(index)}
              >
                Cancel
              </Button>
            </div>
          ))}
        </div>

        {/* Button Grid Layout */}
        <div className="button-grid">
          {/* Top Row */}
          <div className="button-row top-row">
            <Button
              variant="secondary"
              onClick={() => navigate(-1)}
              className="btn-back"
            >
              Back
            </Button>
            <Button
              variant="primary"
              type="button"
              className="btn-submit"
              disabled={images.length === 0}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>

          {/* Bottom Row */}
          <div className="button-row bottom-row">
            <Button
              variant="primary"
              onClick={() => setShowQuestionListModal(true)}
              className="btn-question-list"
            >
              Question List
            </Button>
            <Button
              variant={isSolveEnabled ? "primary" : "secondary"}
              onClick={handleSolve}
              className="btn-solve"
              disabled={!isSolveEnabled}
            >
              Solve
            </Button>
            <Button
              variant="primary"
              onClick={handleExplain}
              className="btn-explain"
            >
              Explain
            </Button>
          </div>
        </div>
      </div>

      {/* Question List Modal */}
      <QuestionListModal 
        show={showQuestionListModal}
        onHide={() => setShowQuestionListModal(false)}
        questionList={questionList}
        onQuestionClick={handleQuestionSelect}
      />
    </div>
  );
}

export default SolveQuestion;