import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Alert, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import './SimilarQuestions.css';

const SimilarQuestions = () => {
  const [similarQuestions, setSimilarQuestions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Fetch similar questions when component mounts
  useEffect(() => {
    fetchSimilarQuestions();
  }, []);

  // Function to fetch similar questions from API
  const fetchSimilarQuestions = async () => {
    try {
      setLoading(true);
      setError('');

      // Check if original question exists in location state
      if (!location.state?.originalQuestion) {
        throw new Error('Original question not found');
      }

      // Make API call
      const response = await axiosInstance.post('/similarquestion/', {
        question: location.state.originalQuestion
      });

      // Set the response data
      setSimilarQuestions(response.data);
    } catch (err) {
      console.error('Error fetching similar questions:', err);
      setError('Failed to fetch similar questions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Handle selecting a question to solve
  const handleQuestionSelect = (question) => {
    navigate('/solvequestion', {
      state: {
        question: question.similar_question,
        class_id: location.state?.class_id,
        subject_id: location.state?.subject_id,
        topic_ids: location.state?.topic_ids,
        subtopic: location.state?.subtopic,
        image: location.state?.questionImage
      }
    });
  };

  // Handle navigation back to dashboard
  const handleBackToDashboard = () => {
    navigate('/student-dash');
  };

  // Show loading spinner while fetching data
  if (loading) {
    return (
      <Container className="similar-questions-container d-flex justify-content-center align-items-center">
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary" />
          <p className="mt-3">Loading similar questions...</p>
        </div>
      </Container>
    );
  }

  // Show error message if there's an error
  if (error) {
    return (
      <Container className="similar-questions-container">
        <Alert variant="danger">
          <Alert.Heading>Error</Alert.Heading>
          <p>{error}</p>
        </Alert>
        <Button 
          variant="secondary"
          onClick={handleBackToDashboard}
          className="mt-3"
        >
          Back to Dashboard
        </Button>
      </Container>
    );
  }

  return (
    <div className="content-wrapper">
      <Container fluid className="similar-questions-container">
        <h2 className="similar-questions-title">Similar Practice Questions</h2>
        
        {similarQuestions && (
          <>
            {/* Original Question Section */}
            <Card className="original-question-card">
              <Card.Header>Original Question</Card.Header>
              <Card.Body>
                <div className="question-content">
                  {location.state?.originalQuestion}
                  {location.state?.questionImage && (
                    <img 
                      src={location.state.questionImage} 
                      alt="Question" 
                      className="img-fluid mt-3"
                    />
                  )}
                </div>
              </Card.Body>
            </Card>

            {/* Theory Concepts Section */}
            {similarQuestions.theory_concepts && (
              <div className="theory-concepts-section">
                <h4>Theoretical Concepts</h4>
                <div className="theory-content">
                  {similarQuestions.theory_concepts.split('\n').map((paragraph, index) => {
                    const trimmedPara = paragraph.trim();
                    
                    if (!trimmedPara) return null;

                    // Format section headers (e.g., "1. CORE CONCEPTS:")
                    if (trimmedPara.match(/^\d+\.\s+[A-Z\s]+:/)) {
                      return (
                        <div key={index} className="concept-item">
                          <h5 className="concept-title">{trimmedPara}</h5>
                        </div>
                      );
                    }

                    // Format bullet points
                    if (trimmedPara.startsWith('-')) {
                      return (
                        <div key={index} className="concept-point">
                          {trimmedPara.substring(1).trim()}
                        </div>
                      );
                    }

                    // Regular paragraphs
                    return <p key={index}>{trimmedPara}</p>;
                  })}
                </div>
              </div>
            )}

            {/* Similar Question Section */}
            {similarQuestions.similar_question && (
              <Card className="mb-4">
                <Card.Header>Practice Question</Card.Header>
                <Card.Body>
                  <Card.Text>{similarQuestions.similar_question}</Card.Text>
                  <Button 
                    variant="primary"
                    onClick={() => handleQuestionSelect(similarQuestions)}
                    className="navigation-button mt-3"
                  >
                    Solve This Question
                  </Button>
                </Card.Body>
              </Card>
            )}

            {/* Navigation Buttons */}
            <div className="navigation-buttons">
              <Button 
                variant="secondary"
                onClick={handleBackToDashboard}
                className="navigation-button back-button"
              >
                Back to Dashboard
              </Button>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default SimilarQuestions;